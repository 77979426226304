import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 391.000000 303.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,303.000000) scale(0.100000,-0.100000)">


<path d="M1313 2641 c-42 -26 -58 -80 -38 -127 25 -61 25 -61 410 -67 385 -6
470 -15 636 -68 243 -77 414 -240 484 -462 37 -117 51 -281 36 -424 -40 -387
-245 -606 -644 -687 -102 -21 -320 -36 -539 -36 l-178 0 0 530 c0 585 1 573
-61 606 -60 31 -139 -9 -153 -77 -10 -54 -7 -1151 4 -1190 6 -20 20 -45 31
-55 20 -18 42 -19 408 -19 324 1 401 3 478 18 198 37 334 88 463 171 276 179
410 461 410 861 0 383 -125 662 -372 832 -95 65 -152 94 -268 132 -187 63
-273 73 -695 78 -367 4 -381 3 -412 -16z"/>
<path d="M980 2272 c-19 -9 -40 -28 -47 -42 -10 -19 -13 -203 -13 -814 l0
-788 34 -34 c49 -49 114 -45 158 10 16 19 18 74 18 744 l0 724 478 -5 c529 -4
552 -7 667 -71 116 -64 165 -163 173 -349 11 -284 -71 -418 -290 -473 -39 -9
-133 -17 -250 -21 -215 -7 -238 -14 -259 -81 -15 -51 8 -104 56 -126 59 -28
374 -13 520 24 222 57 360 192 415 405 55 214 25 488 -69 634 -75 116 -208
204 -366 242 -127 30 -296 38 -765 39 -385 0 -428 -2 -460 -18z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
